import { ref } from 'vue';
import axios from 'axios';
import { initServiceWorker } from './utils.js';
import { scale2seconds } from './utils.js';

const API_URL = window.location.origin;

async function getUserTournaments() {
  const response = await axios.get("/api/tournaments");
  return response.data;
}

async function getTournament(tournamentId) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.get(`/api/tournaments/${tournamentId}`)
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function createTournament(tournamentData, timeSelects) {
  const data = ref(null)
  const error = ref(null)

  tournamentData.time_initial = scale2seconds(tournamentData.time_initial, timeSelects.initial);
  tournamentData.time_per_move = scale2seconds(tournamentData.time_per_move, timeSelects.perMove);

  const request = async () => {
    try {
      const response = await axios.post(`/api/tournaments`, {
        tournament: tournamentData,
      });
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function deleteTournament(tournamentId) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.delete(`/api/tournaments/${tournamentId}`)
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function createParticipant(tournamentId, participant) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/participants`, {
        tournament_id: tournamentId,
        participant: participant,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function joinTournament(tournamentId) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.post(`/api/tournaments/${tournamentId}/join_tournament`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function leaveTournament(tournamentId) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.delete(`/api/tournaments/${tournamentId}/leave_tournament`)
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function startTournament(tournamentId) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/start_tournament`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function endTournament(tournamentId) {
  let data = null
  let error = null
  let status = null

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/end_tournament`)
      data = response.data;
      status = response.status
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error, status };
}

async function reopenTournament(tournamentId) {
  let data = null
  let error = null
  let status = null

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/reopen_tournament`)
      data = response.data;
      status = response.status
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error, status };
}

async function openRegistrations(tournamentId) {
  let data = null;
  let error = null;

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/open_registrations`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function closeRegistrations(tournamentId) {
  let data = null;
  let error = null;

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/close_registrations`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function createRound(tournamentId, params) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.post(`/api/rounds`, {
          tournament_id: tournamentId,
          status: params.status ? params.status : 'published',
        }
      )
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function publishRound(round) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.put(`/api/rounds/${round.id}/publish`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function deleteRound(roundId) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.delete(`/api/rounds/${roundId}`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function updateParticipant(participant) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.put(`/api/participants/${participant.id}`, {
          participant: participant,
        });
      data.value = response;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function toggleParticipantActivation(participant) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.put(`/api/participants/${participant.id}/toggle_participant_activation`);
      data.value = response;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function toggleAllParticipantsActivation(tournamentId, status) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.put(`/api/tournaments/${tournamentId}/toggle_all_participants_activation`, {
        activation_status: status,
      });
      data.value = response;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data: data.value, error: error.value };
}

async function deleteParticipant(participant) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.delete(`${API_URL}/api/participants/${participant.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        }
      });
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function deleteAllParticipants(tournament) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.delete(`${API_URL}/api/tournaments/${tournament.id}/participants/`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        }
      });
      data.value = response.data;
    } catch (err) {
      error.value = err
    }
  }

  await request();

  return { data, error };
}

async function updatePairingResult(payload) {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await axios.put(`/api/pairings/${payload.pairingId}`, {
        result: payload.result,
        referee_override: payload.override,
      });
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

async function notifyRemainingPlayers(tournamentId) {
  const data = ref(null)
  const error = ref(null)

  const request = async () => {
    try {
      const response = await axios.post(`/api/tournaments/${tournamentId}/notify_remaining_players`);
      data.value = response;
    } catch (err) {
      error.value = err
    }
  }
  await request();

  return { data, error };
}

async function subscribeWebpush(auth_token, participant_id) {
  const init = await initServiceWorker();

  if (init.webpushSubscription && (auth_token || participant_id)) {
    await axios.post('/api/webpush_subscriptions', {
      'webpush_subscription': init.webpushSubscription,
      'participant_id': participant_id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`,
      },
    })
    .then(response => {
      return true
    })
    .catch(err => {
      console.error(err);
      return false
    })
  } else {
    console.warn("Webpush not initialized or not subscription not legitimate");
  }
}

async function unsubscribeWebpush(subscription, participant_id) {
  await axios.delete('/api/delete_webpush_subscription', {
    data: {
      'webpush_subscription': subscription,
      'participant_id': participant_id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    return true
  })
  .catch(err => {
    console.error(err);
    return false
  })
}

export default {
  getUserTournaments,

  getTournament,
  createTournament,
  deleteTournament,

  joinTournament,
  leaveTournament,
  startTournament,
  endTournament,
  reopenTournament,
  openRegistrations,
  closeRegistrations,

  createRound,
  publishRound,
  deleteRound,

  createParticipant,
  updateParticipant,
  toggleParticipantActivation,
  toggleAllParticipantsActivation,
  deleteParticipant,
  deleteAllParticipants,

  updatePairingResult,

  notifyRemainingPlayers,

  subscribeWebpush,
  unsubscribeWebpush,
}
